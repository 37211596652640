import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyCcw9BtLW7cUr7ydAE9KWpPCklAu32v994',
  authDomain: 'lunar-prism-405306.firebaseapp.com',
  projectId: 'lunar-prism-405306',
  storageBucket: 'lunar-prism-405306.appspot.com',
  messagingSenderId: '662861585181',
  appId: '1:662861585181:web:05a54e2d6cc5f02d3d1ff4',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app)
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('payload', payload)
      resolve(payload)
    })
  })
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      'BHaUN7SUiyF83pKuzQ1YTY0DIQOprLEEUjgYtMRjO9yzahppijiCpxH7XxgwzgX-gkdtxvtAtMs_IInFFUiJcxI',
  })
    .then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken)
        return currentToken
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        //console.log('No registration token available. Request permission to generate one.')
        return false
      }
    })
    .catch((err) => {
      //console.log('An error occurred while retrieving token. ', err)
      return false
    })
}
