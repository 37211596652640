import React, { Suspense, useEffect, useState, useRef } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { convert } from 'html-to-text'
import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'
import { CToaster, CToastBody, CToastHeader, CToast } from '@coreui/react'
import { onMessageListener } from './firebase'
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const LoginAdmin = React.lazy(() => import('./views/pages/login/LoginAdmin'))

const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'))
const SetNewPassword = React.lazy(() => import('./views/pages/forgotPassword/SetNewPassword'))

const Register = React.lazy(() => import('./views/pages/register/Register'))
const VerifyEmail = React.lazy(() => import('./views/pages/verifyemail/VerifyEmail'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)
  const [notification, setNotification] = useState('')
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  const exampleToast = (title, body) => {
    return (
      <CToast>
        <CToastHeader closeButton>
          <div className="fw-bold me-auto">{title}</div>
        </CToastHeader>
        <CToastBody>{convert(body)}</CToastBody>
      </CToast>
    )
  }

  useEffect(() => {
    if (notification?.body) {
      addToast(exampleToast(notification?.title, notification?.body))
    }
  }, [notification])
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title || 'Custom',
        body: convert(payload?.notification?.body),
      })
    })
    .catch((err) => console.log('failed: ', err))

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, [])

  return (
    <>
      <HashRouter>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <Routes>
            {/* <Route exact path="/dashboard/*" name="Dashboard" element={<DefaultLayout />} /> */}

            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/login-admin" name="Login Page" element={<LoginAdmin />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/set-new-password/:id"
              name="Set New Password Page"
              element={<SetNewPassword />}
            />
            <Route
              exact
              path="/verify-email/:id"
              name="Verify Your Mail Address"
              element={<VerifyEmail />}
            />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

export default App
